import api from '@/services/api';

const url = 'articles';

const getArticles = () => api.get(url);

const createArticles = (articles) => api.post(url, articles);

const updateArticles = (articles) => api.put(url, articles);

const deleteArticles = (id) => api.delete(`${url}/${id}`);

export default {
  getArticles, createArticles, updateArticles, deleteArticles,
};
